.mainContent {
	height: 100%;
	border: 0;
	vertical-align: top;
	text-align: left;
	font-weight: 400;
	padding: 1.5rem;
}

.rightColumn {
	width: 20rem;
	padding-left: 4rem;
	border: 0;
	vertical-align: top;
	text-align: left;
}

.tableContainer {
	width: 100%;
	height: 100%;
	max-width: 90rem;
	margin: 0 auto;
}

.allContainer {
	border: 0;
	width: calc(100% - 6rem);
	height: calc(100% - 8rem);
	padding: 4rem 3rem;
	overflow-y: scroll;
}

.mobileHeader {
	background-color: #17191c;
	color: #cccccc;
	padding: 0.5rem 1rem;
}

.mobileHeaderIcon {
	height: 1rem;
	width: 1rem;
	margin-right: 1rem;
	border-radius: 0.25rem;
}

.mobileHeaderLeft {
	display: flex;
	align-items: center;
}

.mobileHeaderButtonFlex {
	display: flex;
	align-items: center;
}

.mobileContentOuter {
	width: 100%;
	display: flex;
	align-items: center;
	overflow-y: scroll;
}

.mobileContent {
	width: calc(100% - 5rem);
	height: calc(100% - 2.5rem);
	padding: 1.5rem 2.5rem 1rem 2.5rem;
}

.mobileNavMenuHolder {
	width: 22rem;
	max-width: calc(100vw - 6rem);
	height: 100%;
	padding: 3rem;
	background-color: #17191c;
}

.dialogBoxContainer {
	position: fixed;
	right: 1.5rem;
	top: 1.5rem;
	z-index: 3;
}