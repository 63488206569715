.dialogContainer {
    z-index: 3;
    position: fixed;
    top: 7rem;
    right: 1rem;
}

.dialogBox {
    position: relative;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    z-index: 1000;
    margin-bottom: 1.5rem;
    letter-spacing: 0.05rem;
	overflow: hidden;
}

.dialogBox.normal {
    background-color: rgba(0, 100, 153, 0.7);
    color: white;
}

.dialogBox.good {
    background-color: rgba(103, 125, 32, 0.7);
    color: white;
}

.dialogBox.warning {
    background-color: rgba(145, 103, 6, 0.7);
    color: white;
}

.dialogBox.bad {
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
}

.dialogTextContainer {
    line-height: 2rem;
    vertical-align: middle;
    text-align: left;
    padding: 1rem 1.5rem;
}

.dialogBoxText {
    line-height: 2rem;
    vertical-align: middle;
    margin-right: 1.5rem;
    margin-left: 0.5rem;
}

.dialogBoxClose {
    text-align: right;
    line-height: 2rem;
    vertical-align: middle;
    cursor: pointer;
}

.closeIcon {
    line-height: 2rem;
    vertical-align: middle;
    transition: 0.1s ease-in-out;
    color: rgb(242, 242, 242);
}

.closeIcon:hover {
    color: rgb(212, 212, 212);
}

.dialogProgressBar {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 0%;
    height: 0.25rem;
    bottom: 0;
    left: 0;
	animation: shrink 5s ease-in-out;
}

@keyframes shrink {
	0% {
		width: 100%;
	}

	100% {
		width: 0%;
	}
}

.dialogBoxIcon {
    line-height: 2rem;
    vertical-align: middle;
    margin-right: 0.5rem;
}