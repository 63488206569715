#root {
	width: 100%;
	height: 100%;
}

pre {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

a {
	color: #84bef0;
}

p, li {
	font-weight: 400;
	filter: opacity(0.9);
	line-height: 1.75rem;
}

p {
	margin-top: 2rem;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 2rem;
	margin-bottom: 0;
}

#end {
	height: 1px;
}

html, body {
	width: 100%;
	height: 100vh;
}

html {
	overflow-y: clip;
}

body {
	margin: 0;
	background-color: #17191c;
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	overflow-y: auto;
}

* {
	-webkit-user-select: initial;
	-moz-user-select: initial;
	-ms-user-select: initial;
	user-select: initial;
	scrollbar-width: thin;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(242, 242, 242, 0.1);
}

code {
	padding: 0.25rem;
	border-radius: 0.25rem;
}

.math-inline {
	padding: 0.25rem;
	border-radius: 0.25rem;
}

.math-display {
	background-color: rgba(242, 242, 242, 0.03);
	padding: 1rem;
	border-radius: 0.25rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
}